html {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
}

* {
  max-width: 100%;
  font-family: 'Sen', sans-serif!important;
}

h1 {
  color: rgba(255, 255, 255, 0.8);
}

h2 {
  color: #430921;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
}

input {
  color: rgba(0, 0, 0, 0.8);
}

.ant-input-group-addon {
  background-color: none;
}

.ant-input {
  height: 42px;
}

.ant-input-group-addon .ant-btn-lg {
  height: 3.25rem;
  width: 3.25rem;
}

.ant-form-item .ant-form-item-label {
  text-align: center;
  padding: 0;
}

.ant-form-item-label > label {
  color: #f7f7f7;
}

.ant-btn {
  border-radius: 20px;
  border: 0;
  background-color: #222222;
  height: 42px;
  padding: 4px 25px;
}

.ant-btn:hover {
  background-color: #444444;
}

small {
  font-size: .75rem;
}